// China
export const locale = {
	TRANSLATOR: {
		SELECT: "选择你的语言"
	},
	MENU: {
		NEW: "新",
		ACTIONS: "行动",
		CREATE_POST: "创建新帖子",
		PAGES: "Pages",
		FEATURES: "特征",
		APPS: "应用",
		DASHBOARD: "主页",
		INQUIRY_QUOTATION: "询价单"
	},
	AUTH: {
		GENERAL: {
			OR: "要么",
			SUBMIT_BUTTON: "提交",
			NO_ACCOUNT: "没有账号？",
			SIGNUP_BUTTON: "注册",
			FORGOT_BUTTON: "忘记密码",
			BACK_BUTTON: "背部",
			PRIVACY: "隐私",
			LEGAL: "法律",
			CONTACT: "联系",
			SIGNIN_BUTTON: "登录",
			CANCEL_BUTTON: "取消",
		},
		LOGIN: {
			TITLE: "登录帐号",
			BUTTON: "签到",
			MESSAGE: "没有账号?",
			CREATE: "立即注册",
			LOGIN_REQUIRED: "请登录",
			MSG_BAD_CREDENTIALS: "用户名或密码无效",
			MSG_ACCOUNT_NOT_VERIFIED: "您的帐户尚未验证。",
			MSG_ACCOUNT_NOT_VERIFIED_DIALOG:
				"帐户尚未验证.<br /> 如果您没有收到电子邮件，请单击下面，以便您的注册电子邮件地址收到一封新电子邮件。",

		},
		FORGOT: {
			TITLE: "忘记密码?",
			DESC: "输入您的电子邮件以重置您的密码。",
			SUCCESS: "您的帐户已成功重置。",

			TITLE_SUB_ACCOUNT: "忘记子账户密码?",
			DESC_SUB_ACCOUNT: "请联系您管理员或主帐户持有人以执行密码重置程序。",
			RESET_PASSWORD_TITLE: "重置密码",
			FAILED: "忘记邮箱密码无法发送",
			MSG_FORGOT_PASSWORD_LINK_SEND: "忘记密码链接已发送到您的电子邮件。"
		},
		REGISTER: {
			TITLE: "注冊",
			DESC: "请输入您的详细信息以创建您的帐户。",
			SUCCESS: "您的帐户已注册成功。",
			TANDCDESC: "我已阅读及同意",
			TANDC: "条款和协议",
			TERMS_CONDITION: '点击此按钮即表示您同意<a @click="view_tnc()">条款和协议</a>',
			PASSWORD: {
				PASSWORD_LENGTH: "密码须8至12字符",
				CONTAIN_AT_LEAST_LOWER_CASE: "最少1个小写字母",
				CONTAIN_AT_LEAST_UPPER_CASE: "最少1个大写字母",
				CONTAIN_AT_LEAST_ONE_DIGIT: "最少1位数字",
				CONTAIN_AT_LEAST_SPECIAL_CHARACTER: "最少1个特殊字符(#?!@$%^&*-)",
				PASSWORD_REQUIREDMENT_TITLE: "密码要求： "
			},
			MSG_EMAIL_UNIQUE: "邮址已被注册",
			MSG_SUCCESSFUL_REGISTER: "您的帐户已注册成功。请查收您的电子邮件以获取验证电子邮件。"
		},
		INPUT: {
			EMAIL: "电子邮件",
			FULLNAME: "全名",
			PASSWORD: "密碼",
			CONFIRM_PASSWORD: "确认密码",
			USERNAME: "用戶名"
		},
		EMAIL_VERIFICATION: {
			TITLE: "电子邮件验证",
			SUCCESSFUL_VERIFY_TITLE: "已验证",
			SUCCESSFUL_SENT_EMAIL_TITLE: "电子邮件验证链接已发送。",
			SUCCESSFUL_VERIFY: "您已成功验证帐户。点击下方，继续登录",
			FAILED_VERIFY_TITLE: "失败",
			EMAIL_EXPIRED_TITLE: "电子邮件验证链接已过期",
			EMAIL_VERIFIED_TITLE: "电子邮件验证链接已验证",
			INVALID_TOKEN: "电子邮件验证无效",
			TOKEN_EXPIRE: "电子邮件验证链接已过期。<br /> 单击下面将向您的注册电子邮件地址发送一封新电子邮件。 <br/><strong>请注意此链接将在 {hour} 小时内过期。</strong>",
			TOKEN_VERIFIED: "电子邮件验证链接已验证。<br />如果您没有收到电子邮件，请单击下面的按钮以将新电子邮件发送到您的注册电子邮件地址。 <br/><strong>请注意，此链接将在 {hour} 小时内过期</strong>。",
			EMAIL_LABEL: "请输入您的电子邮件地址。<br/><strong>请注意，此链接将在 {hour} 小时内过期</strong>",
			PH_ENTER_EMAIL: "输入电子邮件",
			RESEND_EMAIL_BUTTON: "发送验证邮件",
			RESEND_CANCEL_BUTTON: "返回登录",
			SUCCESSFUL_SENT_EMAIL_MESSAGE: "请点击已发送到您的电子邮件的链接来验证您的注册电子邮件并完成注册过程<br /><strong>请注意，此链接将在 {hour} 小时后过期。</strong>"
		},
		VALIDATION: {
			INVALID: "{name} 无效",
			REQUIRED: "{name} 是必须的",
			MIN_LENGTH: "{name} 最少長度是 {min}",
			AGREEMENT_REQUIRED: "需要接受条款和条件",
			NOT_FOUND: "请求 {name} 未找到",
			INVALID_LOGIN: "登录信息不正确",
			REQUIRED_FIELD: "必填项目",
			MIN_LENGTH_FIELD: "最少字段长度:",
			MAX_LENGTH_FIELD: "最多字段长度:",
			INVALID_FIELD: "字段无效",
			PASSWORD_MISMATCH: "密码和确认密码不相同。",
			PASSWORD_TOO_WEAK: "密码太弱。",
			PASSWORD_DOESNT_MEET_COMPLEXITY_REQUIREMENTS: "密码不符合要求。"
		}
	},
	DASHBOARD: {
		NOTICE: {
			BUTTON: "看一看 !"
		},
		SLIDER: {
			FIRST_SLIDE_DESC: "第一张幻灯片",
			SECOND_SLIDE_DESC: "第二张幻灯片",
			THIRD_SLIDE_DESC: "第三张幻灯片"
		},
		CONTENT: {}
	},
	COMMON: {
		TITLE_LISTING: "{name}列表",
		TITLE_LISTING_DESC: "{name}列表",
		TITLE_CREATE: "添加{name}",
		TITLE_CREATE_DESC: "创建{name}",
		TITLE_UPDATE: "更新{name}",
		TITLE_UPDATE_DESC: "更新{name}详情",
		TITLE_VIEW: "View {name}",
		TITLE_VIEW_DESC: "{name} Details",
		META_TITLE: "{page_title}",
		TITLE_PREVIEW_IMAGE: "预览图片",
		TITLE_PREVIEW_DOC: "预览文档",
		POPULAR_TITLE: "Popular",
		TITLE_UPLOAD_IMAGE: "上传图片",
		TITLE_UPLOAD_DOCUMENT: "上传文档",

		SELECTION_OPTION_TITLE: "请选择",

		SELECTION_ALL_TITLE: "全部",
		BTN_SEARCH: "搜索",
		API_SERVICE_ERROR: "系统错误，请联系系统管理员。",

		COL_ACTION: "",
		BTN_CLOSE: "关闭",
		BTN_PRINT: "打印",
		BTN_SUBMIT: "提交",
		BTN_SAVE_AS_DRAFT: "保存为草稿",
		BTN_CANCEL: "取消",
		BTN_EDIT: "编辑",
		BTN_CREATE: "添加",

		LBL_STATUS: "状态",
		LBL_CUSTOMER_MARKING: "客户代号",
		LBL_WAREHOUSE_TYPE: "仓库类型",

		VALIDATION: {
			INVALID: "{name} 无效",
			REQUIRED: "{name} 是必须的",
			MIN_LENGTH: "{{name}} 最少长度是 {{min}}",
			AGREEMENT_REQUIRED: "需要接受条款和条件",
			NOT_FOUND: "请求的 {{name}} 没有找到",
			REQUIRED_FIELD: "必填项目",
			MIN_LENGTH_FIELD: "最少字段长度:",
			MAX_LENGTH_FIELD: "最多字段长度:",
			INVALID_FIELD: "字段无效"
		},

		MSG_SYSTEM_ERROR: "系统错误",
		SELECTION_OTHERS_TITLE: "其他",
		MSG: {
			NETWORK_ERR: "网络连接不稳定",
			NETWORK_MESS: "我们检测到网络错误。请确保您有稳定的互联网连接，然后重试",
			TIMEOUT_ERROR: "系统超时",
			TIMEOUT_MESS: "您的会话已超时。请重新登录",
			MSG_ERROR_TITLE: "不好意思。。。 。。。",
			NOT_FOUND: "{name} 未找到",
			API_SERVICE_ERROR: "系统错误，请协助系统管理员。",
			SUCCESSFUL_SAVE: "成功保存记录。",
			ARE_YOU_SURE: "你确定吗?",
			MSG_UNABLE_UPLOAD_IMAGE: "无法上传图像。请重试。",
			MSG_BEFORE_LEAVE_PAGE: "您所做的更改可能不会保存。如果您离开页面，这些更改将会丢失。",
			MSG_BEFORE_LEAVE_PAGE_TITLE: "您确定要离开此页面吗?",
			MSG_CODE_EXIST: "编码存在于系统中。",
			MSG_DATA_NOT_FOUND: "没有找到记录",
			COMPLETE_SYNC_ALL: "已完成同步所有记录",
			WARNING_CANNOT_REVERT: "已删除/停用的记录将无法恢复",
			SUCCESSFULL_DELETE: "记录被删除。",
			SUCCESS_INACTIVE: "记录已停用。",
			SUCCESS_TO_DELETE: "{mod} 记录删除成功",
			MSG_NOT_ALLOW_DELETE: "不好意思, 您不允许删除此{{mod}记录",

			IMAGINARY_FILE_SAFE_MSG: "你的虚拟文件是安全的 :)",
			SUCCESSFUL_DELETE_MSG: "您的文件已被删除。",
			NO_MARKING: "客户代号未准备好。您无权访问 {module_page}页面。请联系客户服务。"
		},
		TABLE_EMPTY_MSG: "数据不存在",
		ADDRESS: {
			LBL_ADDRESS: "地址",
			LBL_COUNTRY: "国家",
			LBL_STATE: "州/省",
			LBL_CITY: "城市",
			LBL_POSTCODE: "邮政编码",
			LBL_BUILDING_NAME: "大夏名称",
			LBL_EMAIL: "邮址",
			LBL_UPSTAIRS: "Move Upstairs"
		},
		MSG_WARNING_TITLE: "警告！",
		REQUIRED_TITLE: '<span class="text-danger font-size-h4">*</span><span> 必须填写</span>',
		STATUS: {
			INACTIVE: "不活跃",
			ACTIVE: "活跃",
			SUSPEND: "暂停",
			YES: "是",
			NO: "不是",
			SAVE_AS_DRAFT: "草稿"
		},
		LBL_TEMRS_COND: "点击此按钮即表示您同意",
		TITLE_TERMS_CONDITION: "条款和协议",
		PH_AUTO_DOC_NO: "[Auto]"
	},
	QUOTATION_MANAGEMENT: {
		INQUIRY_QUOTATION: {
			INQUIRY_QUOTATION_TITLE: "询价单",
			REQUEST_FOR_QUOTE_TITLE: "获得报价",
			INQUIRY_QUOTATION_SUMMARY: "询价单详情",
			INQUIRY_QUOTATION_CUSTOMER_TITLE: "Quotation Header",
			PRODUCT_DETAILS_TITLE: "货物详情",
			ORIGIN_DESTIONATION_TITLE: "起运和收货地详情",

			COL_INQUIRY_NO: "询价单号",
			COL_INQUIRY_TIME: "查询日期",
			COL_QUOTATION_NO: "报价单号",
			COL_SHIPMENT_TYPE: "运输类型",
			COL_SHIPMENT_MODE: "运输方式",
			COL_WAREHOUSE: "仓库",
			COL_COUNTRY: "发货/收货国家",
			COL_STATUS: "状态",
			COL_EFFECTIVE_ON: "有效于",
			COL_MARKING: "客户代号",
			COL_REF_TYPE: "货柜",
			COL_PRODUCT_NAME: "货物说明",
			COL_SUPPLIER: "供应商/货代名称",

			LBL_INQUIRY_NO: "查询编号",
			LBL_INQUIRY_ON: "查询日期",
			LBL_EXPIRED_ON: "到期日期",
			LBL_ISSUED_NO: "发布日期",
			LBL_EFFECTIVE_ON: "生效日期",
			LBL_QUOTATION_NO: "报价单号",
			LBL_SHIPMENT_TYPE: "运输类型",
			LBL_SHIPMENT_MODE: "运输方式",
			LBL_MOVEMENT_TYPE: "货运条款",
			LBL_SHIPMENT_TERMS: "装运条款",
			LBL_ORIGIN_COUNTRY: "发货国家",
			LBL_DEST_COUNTRY: "收货国家",
			LBL_QUOTATION_STATUS: "报价状态",
			LBL_CUSTOMER_NAME: "客户名称",
			LBL_EMAIL: "电邮",
			LBL_CONTACT_NO: "手机号",
			LBL_OFFICE_CONTACT_NO: "办公号",
			LBL_BILL_ADDRESS: "账单地址",
			LBL_MARKING: "客户代号",

			EXPIRED_ON_TITLE: "到期日期",
			DRAFT_STATUS: "草案",
			ORIGIN_ADDRESS_TITLE: "起运仓/城市",
			DESTINATION_ADDRESS_TITLE: "收货地",

			MSG_BLOCK_DELETE_QUOTATION: "抱歉，此报价不在 {quote_status}状态。不允许删除。",
			MSG_SUCCESS_DELETE: "这 {inquiry_no} 询价单成功取消。",

			BTN_COPY: "复制",
			BTN_VIEW: "查看",
			BTN_DELETE: "删除",
			BTN_ACKNOWLEDGE: "同意",
			BTN_REJECT: "拒绝",
			BTN_REJECT_N_COPY: "拒绝 & 复制",
			BTN_PREVIEW_QUOTATION: "预览报价单",
			BTN_VIEW_HISTORY: "历史",
			BTN_PRINT_QUOTATION: "打印报价单",
		},
		REQUEST_FOR_QUOTE: {
			FROM_WAREHOUSE_TITLE: "从哪个仓库",
			WAY_TO_SHIP_TITLE: "你想怎么发货？",
			SUPPLIER_DETAILS_TITLE: "发货地信息",
			LOADING_PORT_TITLE: "起运港口",
			FORWARDER_INFORMATION_TITLE: "货运代理信息",
			DELIVERY_ADDRESS_TITLE: "收货地址",
			DESTINATION_PORT_TITLE: "目的港口",
			SHIPMENT_DETAILS_TITLE: "货物详情",
			SPECIAL_REQUEST_TITLE: "特殊要求",

			LBL_COMPANY_NAME: "公司名",
			LBL_PIC: "负责人",
			LBL_EMAIL_ADDRESS: "电子邮件",
			LBL_CONTACT_NUMBER: "联系电话",
			LBL_MARKING: "客户代号",

			WAREHOUSE_SELECTION_OTHERS: "其他",

			PH_PIC: "输入姓名",
			PH_SPECIAL_REQUEST: "输入留言信息...",
			PH_LOADING_PORT: "输入出发港口",
			PH_DESTINATION_PORT: "输入目的港口",

			SHIPPING: {
				LBL_TYPE: "运输类型",
				LBL_MODE: "运输模式",
				LBL_MOVEMENT: "运货条款",
				LBL_TERM: "装运条款",
				LBL_CURRENCY: "货币"
			},
			SUPPLIER: {
				LBL_NAME: "供应商/货代名称",
				LBL_ADDRESS: "地址",
				LBL_POSTCODE: "邮政编码",
				LBL_CITY: "城市",
				LBL_STATE: "州属",
				LBL_CONTACT_NUMBER: "供应商联/货代系电话",
				LBL_CONTACT_NUMBER_CALLING_CODE: "供应商联/货代电话编码",
				COL_MOBILE_CONTACT_NO_PIC: "Person In Charge",
			},
			FORWARDER: {
				LBL_COMPANY: "货运代理公司名称",
				LBL_PIC: "货代代理负责人",
				LBL_CONTACT_NUMBER: "货运代理联系电话",
				LBL_CONTACT_NUMBER_CALLING_CODE: "货运代理联系电话代码",

				PH_PIC: "货运代理名称"
			},
			DELIVERY: {
				LBL_ADDRESS_NAME: "收货地",
				LBL_ADDRESS: "地址",
				LBL_BUILDING_NAME: "建筑名称",
				LBL_UPSTAIRS: "上楼",
				LBL_COUNTRY: "国家",
				LBL_STATE: "州属",
				LBL_CITY: "城市",
				LBL_POSTCODE: "邮政编码"
			},
			DETAILS: {
				LBL_PRODUCT_CATEGORY: "货物说明",
				LBL_BRAND_DESCRIPTION: "品牌说明",
				LBL_REF_TYPE: "货柜类型",
				LBL_PARCEL_LOST_WARRANTY: "包裹丢失保险",
				LBL_SPECIAL_MATERIAL: "特殊材料",
				LBL_GOODS_AMOUNT: "货物金额",
				LBL_ESTIMATED_DIMENSION: "总尺寸 (M3)",
				LBL_ESTIMATED_WEIGHT: "总重量 (KG)",
				LBL_PHOTO: "货品图片",
				LBL_REMARKS: "备注",
				LBL_TOTAL_CARTON: "总箱数",

				BTN_COPY: "复制",
				BTN_ADD: "添加",
				BTN_DELETE: "取消",
				BTN_UPLOAD_PHOTO: "上传产品图片",
				BTN_SUPPORT_DOCUMENT: "上传支持文件",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "货物描述例子： 衬衫、鞋子、玩具",
				EXAMPLE_ENTER_BRAND: "品牌例子： 阿迪达斯、耐克"
			},

			BTN_SUBMIT: "提交",
			BTN_RESET: "重置",
			SUCCESS: "询价报价已生成，询价编号： {inquiry_no}。",
			FAILED: "哎呀……，抱歉，无法生成询价报价。 请再试一次。",
			MSG_FAILED_UPLOAD_IMGAE: "哎呀....，抱歉产品图片上传失败",
			MSG_FAILED_UPLOAD_DOCUMENT: "哎呀.. .., 抱歉文件上传失败",
			MSG_NO_ADDRESS: "请在地址簿中添加新地址",
			MSG_BILL_ADDRESS_NOT_FOUND: "未找到帐单邮寄地址，请设置。",
			UPLOAD: {
				PRODUCT_IMAGE: "上传产品图片",
				SUPPORTED_DOCUMENT: "上传支持文件",
				LBL_UPLOAD_PHOTO: "点击选择图像。",
				LBL_UPLOAD_FILE: "点击选择文件。",
				SUPPORT_IMAGE_SIZE: "支持的大小应小于 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "支持的大小应小于 1 MB 和格式 .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "支持的大小应小于 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "支持的大小应小于 10 MB! 和格式 .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "成功并存为草稿",
			MSG_BLOCK_EDIT: "您不被允许编辑此询价报价单。",
			MSG_CONFIRMATION_SUBMIT: "提交前请确保所有详细信息均正确无误。",
			RESET_FORM_CONFIRMATION: "所有信息将被重置。 你确定吗？",
			MSG_PERSONAL_DATA_INCOMPLETE: "个人资料不完整。 请先完成"
		},
		QUOTATION_DETAIL: {
			LBL_AGREE_TERMS_COND: "点击此即表示您同意",
			LBL_AGREE_TERMS_COND1: "条款与协议",
			TERMS_COND_TITLE: "条款与协议",
			ACKNOWLEDGE_QUOTATION_TITLE: "报价详情",
			ADDON_TNC_DESC: "我们公司保留添加、修订、年度、修改或更改此处的任何条款和条件的权利，恕不另行通知。",
			QUOTATION_NOT_EXISTS: "报价不存在",
			NO_ALLOW_REJECT_QUOTATION: "不允许拒绝报价",
			NO_ALLOW_ACKNOWLEDGE_QUOTATION: "不允许同意报价",
			QUOTATION_EXPIRE_MESSAGE: "{quotation_no} 将在{expired_on}过期  "
		},
		REJECT_QUOTATION: {
			TITLE: "拒绝报价",
			LBL_QUOTATION_NO: "报价单号: ",
			LBL_REJECT_REASON: "拒绝原因"
		}
	},
	EPO_MANAGEMENT: {
		EPO_FORMAT: {
			EPO_FORMAT_PRINT_TITLE: "打印托运单",
			PREVIEW_TITLE: "预览托运单{epo_no}"
		},
		EPO_LISTING: {
			COL_EPO_NO: "托运单编号",
			COL_SHIPMENT_TYPE: "运输类型",
			COL_SHIPMENT_MODE: "运输方式",
			COL_WAREHOUSE: "仓库",
			COL_SUPPLIER: "供应商/货代名称",
			COL_STATUS: "状态",
			COL_MARKING: "客户代号",
			COL_PRODUCT_NAME: "商品描述",
			MODULE_NAME: "EPO",

			BTN_COPY: "复制",
			BTN_VIEW: "查看",
			BTN_DELETE: "删除"
		},
		ORDER: {
			LISTING_TITLE: "托运单列表",
			CREATE_ORDER_TITLE: "添加新托运单",
			EPO_ORDER_TITLE: "托运单详情 {epo_no}",
			EPO_ORDER_COPY_TITLE: "复制{epo_no}托运单",
			EPO_INFO_TITLE: "托运单信息",
			WAREHOUSE_LOCATION: "仓库位置",
			WAREHOUSE_DETAILS_TITLE: "仓库详情",
			FROM_WAREHOUSE_TITLE: "从哪个仓库",
			DELIVERY_ADDRESS_TITLE: "收货地信息",
			SUPPLIER_DETAILS_TITLE: "发货地信息",
			PRODUCT_DETAILS_TITLE: "运单 / 货物详情",
			SPECIAL_REQUEST_TITLE: "特殊要求",
			LBL_EPO_DATE: "托运单日期",
			AGREE_TNC: "托运单条款及协议",
			CUSTOMER_NOTE_TITLE: "客户备注",
			MSG_TNC_CHECKBOX: "接受托运单条款和协议",
			MSG_EPO_FORMAT_SETTINGS: "托运单格式设置不匹配。请联系客服",
			MSG_NOT_QUOTATION_ACKNOWLEDGE: "<br />所有信用条款和服务费均取决于最终确认报价单。",

			PH_ENTER_EPO_DATE_FROM: "托运单日期从",
			PH_ENTER_EPO_DATE_TO: "托运单日期至",



			LBL_EPO_NO: "托运单号",
			LBL_EPO_TYPE: "托运单类型",
			LBL_PO_EXISTS: "是否已有PO?",
			LBL_CURRENCY: "货币",
			LBL_COMPANY: "货运代理公司名称",
			LBL_MARKING: "客户代号",
			LBL_SUPPLIER: "供应商/货代名称",
			LBL_SUPPLIER_ORDER_NO: "供应商订单号",
			LBL_SUPPLIER_BANK_NAME: "供应商银行名称",
			LBL_SUPPLIER_BANK_ACCOUNT_NAME: "供应商银行户口名称",
			LBL_SUPPLIER_BANK_ACCOUNT_NO: "供应商银行户口号码",
			LBL_SUPPLIER_BANK_BRANCH: "供应商银行分行",
			LBL_EXISTS_PO_NO: "PO编号.",
			LBL_DEPOSIT: "订金",
			LBL_ESTIMATE_DATE_DELIVERY: "预计交货日期",
			LBL_WAREHOUSE_TYPE: "仓库类型",
			LBL_WAREHOUSE: "仓库",
			LBL_PRODUCT_REMARK: "备注",
			LBL_EPO_SUBMIT_DATE: "托运单日期",
			LBL_DATE_FROM: "日期从",
			LBL_DATE_TO: "日期至",
			LBL_EPO_STATUS: "托运单状态",

			LBL_SUB_TOTAL: "小计",
			LBL_TOTAL_DISCOUNT: "总折扣",
			LBL_GRAND_TOTAL_AMOUNT: "总计",

			EPO_TYPE_SELECTION_ORDER: "托运单",
			EPO_TYPE_SELECTION_COURIER: "有运单",

			WAREHOUSE_SELECTION_OTHERS: "其他",

			PH_SPECIAL_REQUEST: "输入留言信息...",

			BTN_NEW_SUPPLIER: "添加新供应商",

			COL_EPO_NO: "托运单编号",
			COL_CREATED_AT: "创建日期",
			COL_SHIPMENT_TYPE: "运输类型",
			COL_SHIPMENT_MODE: "运输方式",
			COL_DELIVERY_ID: "送货编号",
			COL_EPO_DATE: "托运单日期",
			COL_WAREHOUSE: "仓库",
			COL_SUPPLIER: "供应商/货代名称",
			COL_MARKING: "客户代号",
			COL_EPO_TYPE: "托运单类型",
			COL_TOTAL_DEPOSIT: "总预付金额",
			COL_GRAND_TOTAL: "总额.",

			SHIPPING: {
				LBL_TYPE: "运输类型",
				LBL_MODE: "运输模式"
			},

			SUPPLIER: {
				LBL_NAME: "供应商/货代名称",
				LBL_ADDRESS: "地址",
				LBL_POSTCODE: "邮政编码",
				LBL_CITY: "城市",
				LBL_STATE: "州属",
				LBL_CONTACT_NUMBER: "供应商联/货代系电话",
				LBL_CONTACT_NUMBER_CALLING_CODE: "供应商联/货代电话编码",
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "收货地址",
				LBL_ADDRESS: "地址",
				LBL_BUILDING_NAME: "建筑名称",
				LBL_UPSTAIRS: "上楼",
				LBL_COUNTRY: "国家",
				LBL_STATE: "州属",
				LBL_CITY: "城市",
				LBL_POSTCODE: "邮政编码"
			},

			PRODUCT: {
				COL_COURIER: "快递公司",
				COL_ITEM_CODE: "货号",
				COL_PRODUCT_NAME: "商品名",
				COL_BRAND: "品牌",
				COL_CATEGORY_GROUP: "商品类型",
				COL_CATEGORY_GROUP_TYPE: "商品类型选项",
				COL_CATEGORY_GROUP_OTHERS: "其他特殊商品",
				COL_QUANTITY: "数量",
				COL_UOM: "单位",
				COL_UNIT_PRICE: "单价",
				COL_DISCOUNT_AMOUNT: "折扣价",
				COL_TOTAL_AMOUNT: "总金额",
				COL_REMARKS: "备注",
				COL_PHOTO: "照片",
				COL_INV_DESC: "发票货名",

				LBL_CONSIGNMENT_NO: "快递单号",

				BTN_COPY: "复制",
				BTN_ADD: "添加",
				BTN_DELETE: "取消",
				BTN_UPLOAD: "上传",
				BTN_CHANGE_CATEGORY_GROUP: "更改商品类型",
				BTN_ADD_CONSIGNMENT_NO: "添加快递单号",
				BTN_UPLOAD_PHOTO: "上传产品图片",
				BTN_SUPPORT_DOCUMENT: "上传支持文件"
			},

			CATEGORY_GROUP_TYPE: {
				MODAL_TITLE: "商品类型详细",

				LBL_CATEGORY_TYPE: "商品类型",
				LBL_CATEGORY_GROUP_TYPE: "{category_group_name} 特殊商品选项",
				LBL_OTHER_CATEGORY_GROUP_TYPE: "其他",
				LBL_VALUABLE_OBJECTS: "贵重物品",
				LBL_FRAGILE_ITEMS: "易碎物品",
				LBL_OTHER_SENSITIVE_ITEMS: "其他特殊商品",
				LBL_FAKE_BRANDS: "仿牌",

				PH_OTHER_CATEGORY_GROUP_TYPE: "请输入并描述 {category_group_name} 特殊商品",
				PH_FAKE_BRANDS: "请列出仿牌名称"
			},
			UPLOAD: {
				PRODUCT_IMAGE: "上传产品图片",
				SUPPORTED_DOCUMENT: "上传支持文件",
				LBL_UPLOAD_PHOTO: "点击选择图像。",
				LBL_UPLOAD_FILE: "点击选择文件。",
				SUPPORT_IMAGE_SIZE: "支持的大小应小于 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "支持的大小应小于 1 MB 和格式 .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "支持的大小应小于 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "支持的大小应小于 10 MB! 和格式 .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},

			NEW_SUPPLIER: {
				MODAL_TITLE: "添加新供应商"
			},

			BTN_SUBMIT: "提交",
			BTN_RESET: "重置",

			SUCCESS: "托运单已生成, 询价编号: {epo_no}。",
			FAILED: "哎呀...抱歉, 无法生成托运单. 请再试一次。",
			MSG_FAILED_UPLOAD_IMGAE: "哎呀...抱歉, 产品图片上传失败",
			MSG_FAILED_UPLOAD_DOCUMENT: "哎呀...抱歉, 文件上传失败",
			MSG_NO_ADDRESS: "请在地址簿中添加新地址",

			MSG_GRAND_TOTAL_AMOUNT_NEGATIVE: "总金额不能为负数",
			MSG_PRODUCT_TOTAL_AMOUNT_NEGATIVE: "产品总金额不能为负数",
			MSG_PRODUCT_UNIT_PRICE_EMPTY: "警告！ 您的产品单价包含零值或空值",

			MSG_SUCCESFUL_SAVE_AS_DRAFT: "成功并存为草稿",
			MSG_BLOCK_EDIT: "您不被允许编辑此托运单。",
			MSG_DOCUMENT_TYPE: "没有与当前客户代号匹配的文档类型。 请联系管理员",
			MSG_DOCUMENT_NUMBER: "没有与当前客户代号匹配的文件编号。 请联系管理员",

			MSG_CONFIRMATION_SUBMIT: "提交前请确保所有详细信息均正确无误。",
			MSG_PERSONAL_DATA_INCOMPLETE: "个人资料不完整。 请先完成",
			RESET_FORM_CONFIRMATION: "所有信息将被重置。 你确定吗？"
		}
	},
	CUSTOMER: {
		SIDE_BAR: {
			DASHBOARD: "主页",
			PROFILE_AND_ACCOUNT: "账户",
			DELIVERY_ADDRESS: "收货地址",
			EMAIL: "电子邮件",
			PHONE: "电话",
			COMPANY_NAME: "公司名称",
			COMPANY_MANAGEMENT: "账号管理",
			PERSONAL_INFORMATION: "账号详情",
			ADDRESS_BOOK: "收货地址",
			ADD_NEW_ADDRESS_LISTING_TITLE: "将新地址添加到列表中",
			LEDGER_TRANSACTION: "账户明细",
			NOTIFICATION: "通知",
			CHANGE_PASSWORD: "更改密码",
			SUB_ACCOUNT: "子帐户",
			ORDER: "托运单",
			WAREHOUSE_ADDRESS: "代理仓库",
			NICKNAME: "昵称",
			LOGIN_EMAIL: "登录邮址",
			ACCOUNT_MANAGEMENT: "账号管理",
			ROLE_MANAGEMENT: "角色管理",
			MARKING: "客户代号",
			SUPPLIER: "供应商管理",
			DATA_FILTER: "数据筛选管理"
		},
		PERSONAL_INFO: {
			PERSONAL_INFO_TITLE: "户口信息",
			UPDATE_PERSONAL_INFO_TITLE: "更新您的户口信息",
			CUSTOMER_INFO_TITLE: "顾客信息",
			CONTACT_INFO_TITLE: "联系方式",
			CHANGE_PASSWORD: "更新密码",
			LBL_AVATAR: "头像",
			LBL_FIRST_NAME: "First Name",
			LBL_LAST_NAME: "Last Name",
			LBL_FULL_NAME: "全名",
			LBL_COMPANY_NAME: "公司名",
			LBL_CONTACT_PHONE: "联系电话",
			PH_CONTACT_PHONE: "电话",
			LBL_EMAIL_ADDRESS: "电子邮件地址",
			PH_EMAIL_ADDRESS: "电子邮件",
			LBL_COMPANY_SITE: "公司网站",
			PH_COMPANY_SITE: "网址",

			AVATAR_MESSAGE: "允许的文件类型: png, jpg, jpeg.",
			COMPANY_NAME_MESSAGE: "如果您希望将发票寄给公司，留空处请使用您的全名。",
			CONTACT_PHONE_MESSAGE: "我们绝不会与任何其他人分享您的联系电话。"
		},
		DELIVERY_ADDRESS: {
			CREATE_NEW_ADDRESS_TITLE: "添加新收货地址",
			EDIT_ADDRESS_BOOK: "编辑收货地址",
			ADDRESS_BOOK_DETAIL: "收货地址详情",
			PIC_TITLE: "负责人信息",
			DELIVERY_ADDRESS_TITLE: "收货地址",
			CONTACT_INFORMAION_TITLE: "联系信息",
			STATES_VERIFY_TITLE: '以下州属的地址需要管理员验证.<br />如报价单存在, 则以下州的地址不允许编辑：<span class="text-capitalize">{states}</span>',


			LBL_ADDRESS_NAME: "公司名称",
			LBL_DELIVERY_ID: "送货编号",
			LBL_PIC_NAME: "负责人姓名",
			LBL_MOBILE_CONTACT_NO: "手机号",
			LBL_MOBILE_CONTACT_NO_SECOND: "手机号（2）",
			LBL_OFFICE_CONTACT_NO: "办公室联络号码",
			LBL_BUILDING_NAME: "建筑名称",
			LBL_ADDRESS: "地址",
			LBL_COUNTRY: "国家",
			LBL_STATE: "洲",
			LBL_CITY: "城市",
			LBL_POSTCODE: "邮政编码",
			LBL_UPSTAIRS: "楼上",
			UPSTAIRS_YES: "是",
			LBL_BILLING_ADDRESS: "帐单地址",
			BILLING_ADDRESS_YES: "是",
			LBL_BRN: "商业登记号码",

			EMPTY_MSG: "请输入",
			SUCCESFUL_SAVE_MSG: "地址创建成功",
			SUCCESFUL_UPDATE_MSG: "地址更新成功",
			MSG_BILL_ADDRESS_CONFIRMATION: "您已设置账单地址。您确定更改吗？",

			LBL_MOBILE_CONTACT_NO_PIC: "手机联系人",
			LBL_MOBILE_CONTACT_NO_PIC2: "手机(Alt)联系人",

			LBL_MOBILE_CONTACT_NO_CALLING_CODE: "手机编码",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE1: "手机(Alt)编码",

			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "办公室联络编码",
			LBL_OFFICE_CONTACT_NO_PIC: "办公室负责人",
			LBL_DELIVERY_ADDRESS_RECEIVING_HOUR: "收货时间",
			LBL_DELIVERY_ADDRESS_RECEIVING_DAY: "收货日期",
			RECEIVING_HOUR_REMARK: "上午 09:00 至下午 06:00 之间送货对于任何收货时间的问题，您可以联系客服",


			DELIVERY_ID_VALIDATION: {
				DELIVERY_ID_LENGTH: "必须 {min} - {max}个字符.",
				DELIVERY_ID_SPECIAL_CHARACTER: "仅允许{len}个特殊字符({special_character}) /任何字母及数字.",
				DELIVERY_ID_UNIQUE: "送货编号可用"
			},

		},
		ADDRESS_LISTING: {
			ADDRESS_LISTING_TITLE: "收货地址列表",
			DELIVERY_ID: "送货编号",
			ADD_NEW_ADDRESS_LISTING_TITLE: "将新地址添加到列表中",
			EDIT_ADDRESS_BOOK: "编辑地址",
			REMARK_ADDRESS_NAME_TITLE: "公司名称",
			REMARK_DEFAULT_BILLING_ADDRESS: "帐单地址",
			ADDRESS: "地址"
		},
		SUPPLIER: {
			MODULE_NAME: "供应商",
			NEW_SUPPLIER: "新供应商",
			TITLE_ADDRESS_INFO: "供应商地址信息",
			TITLE_CONTACT_INFO: "供应商联络信息",
			EDIT_SUPPLIER: "编辑供应商",
			EXISTING_SUPPLIER_DETAIL: "游览供应商",

			LBL_NAME: "供应商名称",
			LBL_PIC_NAME: "负责人姓名",
			LBL_MOBILE_CONTACT_NO: "手机联系号码",
			LBL_OFFICE_CONTACT_NO: "办公室联络号码",
			LBL_MOBILE_CONTACT_NO_SECOND: "手机(Alt)联系号码",
			LBL_BUILDING_NAME: "建筑名称",
			LBL_ADDRESS: "地址",
			LBL_COUNTRY: "国家",
			LBL_STATE: "洲",
			LBL_CITY: "城市",
			LBL_POSTCODE: "邮政编码",
			LBL_EMAIL: "电子邮件",
			LBL_REMARK: "备注",

			EMPTY_MSG: "请输入",
			SUCCESFUL_SAVE_MSG: "供应商创建成功",
			SUCCESFUL_UPDATE_MSG: "供应商更新成功",

			LBL_MOBILE_CONTACT_NO_PIC: "手机联系人",
			LBL_MOBILE_CONTACT_NO_PIC2: "手机(Alt)联系人",

			LBL_MOBILE_CONTACT_NO_CALLING_CODE: "手机编码",
			LBL_MOBILE_CONTACT_NO_CALLING_CODE1: "手机(Alt)编码",

			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "办公室联络编码",
			LBL_OFFICE_CONTACT_NO_PIC: "办公室负责人",

			COL_SUPPLIER_NAME: "名称",
			COL_SUPPLIER_ADDRESS: "地址",
			COL_MOBILE_CONTACT_NO_PIC: "办公室负责人"
		},
		ACCOUNT_MANAGEMENT: {
			SETUP_ACCOUNT: "创建账号",
			ACCOUNT_DETAIL_TITLE: "账号详情",
			PROCESS_STATE: "进程状态",
			LBL_EXTRA_EMAIL_ALT: "其他邮址",
			LBL_EXTRA_EMAIL_CAPTION: "例子: test_1@mail.com ; test_2@mail.com",
			ACCOUNT_MANAGEMENT_TITLE: "户口列表",
			ADD_NEW_ACCOUNT_MANAGEMENT_TITLE: "将新户口添加到列表中",
			EDIT_ACCOUNT_MANAGEMENT: "修改账号信息",
			ACCOUNT_MANAGEMENT_DETAILS: "账号详情",
			ACCOUNT_UPDATE_LOG: "帐户更新日志",
			ADMIN_VERIFLYING: "管理员正在验证您的帐户，请稍后再回来.",
			WAITTING_FOR_ADMIN_APPROVE: "等待管理员批准",
			ACCOUNT_NAME: "账户名称/公司名称",
			NRIC: "身份证/护照",
			COMPANY_NAME: "公司名称",
			PENDING_ACCOUNT: "温馨提示: 您仍有待处理的帐户",
			BUSINESS_REGISTER_NUMBER_NRIC: "商业登记号码 / 身份证",
			BUSINESS_REGISTER_NUMBER: "商业登记号码",
			CONTACT_NUMBER: "联系电话",
			BILLING_ADDRESS_TITLE: "帐单地址",
			CREATE_NEW_ACCOUNT: "创建新帐户",
			LBL_ACCOUNT_NAME: "帐户名称",
			CONTACT_INFORMAION_TITLE: "联系信息",
			TNC_CHECKBOX: "您必须同意继续!",
			LBL_EMAIL: "电子邮件",
			LBL_EXTRA_EMAIL: "添加另一个电子邮件",
			LBL_MOBILE_CONTACT_NO: "手机号",
			LBL_MOBILE_CONTACT_NO_SECOND: "手机号(2)",
			LBL_MOBILE_CONTACT_NO_PIC: "手机联系人",
			LBL_MOBILE_CONTACT_NO_PIC2: "手机(Alt)联系人",
			LBL_OFFICE_CONTACT_NO_CALLING_CODE: "办公室联络编码",
			LBL_OFFICE_CONTACT_NO_PIC: "办公联系人",
			LBL_PIC_NAME: "负责人姓名",
			LBL_BUILDING_NAME: "建筑名称",
			LBL_UPSTAIRS: "楼上",
			LBL_BILLING_EMAIL: "帐单电子邮件",
			UPSTAIRS_YES: "是",

			LBL_POSTCODE: "邮政编码",
			LBL_CONTACT_NO: "手机号",
			LBL_CONTACT_NO_PIC: "手机联系人",
			LBL_BUSINESS_REGISTER_NUMBER: "商业登记号码",
			SUCCESFUL_SAVE_MSG: "成功！ 您的提交已保存!",

			COL_REQUEST_TYPE: "请求类型",
			COL_ACCOUNT_NAME: "账户名称/公司名称",
			COL_NRIC_OR_BRN: "商业登记号码 / 身份证",
			COL_PROCESS_STATE: "进程状态",
			RADIO_INDIVIDUAL: "个人",
			RADIO_COMPANY: "企业",
			LBL_NAME: "名称",
			LBL_COMPANY_NAME: "企业名称",
			LBL_NRIC: "身份证/护照证",
			LBL_BRN: "企业认证",
			LBL_OFFICE_CONTACT_NO: "办公号",
			LBL_NRIC_BRN: "身份证/护照证/企业认证",
			LBL_PHOTO: "上传身份证/护照证照片",
			LBL_COMPANY_PHOTO: "上传企业认证照片",
			SUCCESFUL_SETUP_ACCOUNT: "帐户已成功创建",
			LBL_NICKNAME: "昵称",
			LBL_LOGIN_EMAIL: "登录邮址",
			CUSTOMER_DETAILS_TITLE: "账户详情",
			COL_NEW_ACCOUNT_NAME: "新企业名称",
			COL_NEW_BRN: "身份证/护照证/企业认证",
			UPLOAD_PHOTO: "上传身份证/护照证/企业认证",
			LBL_NEW_BRN: "新企业认证"
		},
		SUB_ACCOUNT_MANAGEMENT: {
			SUB_ACCOUNT_MANAGEMENT_TITLE: "子账户列表",
			CREATE_NEW_SUB_ACCOUNT: "创建子账户",
			EDIT_SUB_ACCOUNT: "编辑子账户",
			VIEW_SUB_ACCOUNT: "查看子账户",
			COL_USERNAME: "用户名",
			COL_NICKNAME: "昵称",
			COL_ROLE: "角色",
			COL_STATUS: "状态",
			COL_CREATED_BY: "创建者",
			COL_CREATED_AT: "创建时间",
			COL_LAST_UPDATED_BY: "最后修改者",
			COL_UPDATED_AT: "最后修改时间",
			COL_LAST_LOGIN: "最后一次登录",

			LBL_USERNAME: "用户名",
			LBL_USERNAME_DESCRIPTION: "用户全名: ",
			LBL_USERNAME_DESCRIPTION_2: "用户名不能更改",
			LBL_NICKNAME: "昵称",
			LBL_NICKNAME_DESCRIPTION: "昵称可以稍后更改",
			LBL_USERNAME_PREFIX_TOOLTIP_TITLE: "用户名前缀",
			LBL_USERNAME_PREFIX_TOOLTIP: "此用户名前缀是自动生成的。 用户必须使用完整的用户名登录，例如",
			LBL_ONE_TIME_PASSWORD: "一次性密码",
			LBL_ONE_TIME_PASSWORD_DESCRIPTION: "用户首次登录时需要重置密码",
			LBL_ROLE: "角色",
			LBL_EMAIL: "电子邮件",
			LBL_TO_SEND_EMAIL_DESCRIPTION: "一封附有登录说明的电子邮件，包括用户名和一次性密码，将发送到提供的电子邮件地址",
			LBL_DATA_FILTER: "数据筛选",
			LBL_IGNORE_FILTER: "忽略数据筛选",
			LBL_CREATED_BY: "创建者",
			LBL_CREATED_AT: "创建于",
			LBL_LAST_UPDATED_BY: "修改者",
			LBL_LAST_UPDATED_AT: "修改时间",
			LBL_LAST_LOGIN: "最后一次登录",
			LBL_STATUS: "状态",

			CHECKBOX_TO_SEND_EMAIL: "发送登录说明",

			BTN_CREATE_ROLE: "创建角色",
			CREATED_MSG: "子账号已创建。",
			UPDATED_MSG: "子账户详情已更新。"
		},
		DATA_FILTER: {
			LISTING_TITLE: "数据筛选列表",
			CREATE_NEW_TITLE: "创建数据筛选",
			EDIT_INFO: "编辑数据筛选信息",
			DETAILS: "数据筛选信息详情",
			CREATE_NEW: "创建数据筛选",
			EDIT: "编辑数据筛选",
			NAME: "数据筛选名称",
			DESCRIPTION: "数据筛选说明",
			SUB_ACCOUNT: "子账户",
			MARKING: "标记",
			ASSIGN_TO_CUSTOMER: "指派给客户",
			MODIFY_BY: "修改者",
			SUCCESSFUL_SAVE_MSG: "数据筛选已成功创建",
			SUCCESSFUL_UPDATE_MSG: "提交成功",
			ERROR_NAME_EXISTS_MSG: "名称存在。 请选择一个新名称。"
		},
		ROLE_MANAGEMENT: {
			ROLE_LISTING_TITLE: "角色列表",
			CREATE_NEW_ROLE_TITLE: "创建新角色",
			EDIT_ROLE_MANAGEMENT: "编辑角色信息",
			ROLE_MANAGEMENT_DETAILS: "角色信息详情",
			CREATE_NEW_ROLE: "创建新角色",
			EDIT_ROLE: "编辑角色",
			ROLE_NAME: "角色名称",
			ROLE_DESCRIPTION: "角色描述",
			MODIFY_BY: "修改人",
			SUCCESSFUL_SAVE_MSG: "角色创建成功",
			SUCCESSFUL_UPDATE_MSG: "提交成功."
		},
		CHANGE_PASSWORD: {
			CHANGE_PASSWORD: "更改密码",
			SUCCESFUL_UPDATE_MSG: "更改密码成功",
			CURRENT_PASSWORD: "当前密码",
			FORGET_PASSWORD: "忘记密码",
			NEW_PASSWORD: "新密碼",
			VERIFY_PASSWORD: "验证密码",
			PASSWORD_EXPIRE: "配置用户密码定期过期。 警告:用户需密码即将过期需要更改，否则会无意中被系统锁定！"
		},
		BUTTON: {
			SAVE: "保存",
			CANCEL: "取消"
		},
		MARKING: {
			CUSTOMER_MARKING: "客户代号",
			CUSTOMER_MARKING_ID: "Customer Marking ID",
			MARKING: "代号",
			CREDIT_TERMS: "信用条款",
			COMPANY_ID: "公司",
			DAYS: "Days",
			CREDIT_LIMIT: "信用额度",
			STATUS: "状态"
		}
	},
	HEADER: {
		HOME: "首頁",
		OUR_SERVICE: "我们的服务",
		QUICK_USER: {
			QUICK_USER_TITLE: "用户资料",
			BTN_SIGNOUT: "登出",
			MY_PROFILE: "个人资料",
			MY_PROFILE_DESCRIPTION: "帐户设置和其他",
			NO_AUTHENTICATION_TITLE: "登录"
		}
	},
	FOOTER: {
		GIMSYSTEM: "GBizs System",
		NAVIGATION: {
			ABOUT: "关于",
			TEAM: "团队",
			CONTACT: "联系"
		}
	},
	WAREHOUSE: {
		TITLE: "代理仓库",
		SUB_TITLE: "当您在网站进行购物时，请填写以下的收货地址:",
		LBL_CONTACT: "联络",
		LBL_REMARK: "备注",
		LBL_MOBILE: "手机"
	},
	CUSTOMER_MANAGEMENT: {
		CUSTOMER_SUPPLIER: {
			LBL_CUSTOEMR_SUPPLIER_CUSTOMER: "Customer",
			LBL_CUSTOEMR_SUPPLIER_NAME: "供应商名称",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_PIC_NAME: "手机负责人",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_NO: "手机号",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_PIC_NAME: "手机号(Alt)负责人",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_NO: "手机号(Alt)",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_PIC_NAME: "办公号负责人",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_NO: "办公号",
			LBL_CUSTOMER_SUPPLIER_BUILDING_NAME: "大夏名称",
			LBL_CUSTOMER_SUPPLIER_ADDRESS: "地址",
			LBL_CUSTOMER_SUPPLIER_POSTCODE: "邮政编码",
			LBL_CUSTOMER_SUPPLIER_OTHER_POSTCODE: "其他邮政编码",
			LBL_CUSTOMER_SUPPLIER_CITY: "城市",
			LBL_CUSTOMER_SUPPLIER_OTHER_CITY: "其他城市",
			LBL_CUSTOMER_SUPPLIER_STATE: "州/省",
			LBL_CUSTOMER_SUPPLIER_COUNTRY: "国家",
			LBL_CUSTOMER_SUPPLIER_EMAIL: "邮址",
			LBL_CUSTOMER_SUPPLIER_REMARK: "备注"
		}
	},
};
